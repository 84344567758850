import React from 'react'
import PrivacyPolicyTerms from '../components/Policies/PrivacyPolicyTerms';

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <PrivacyPolicyTerms />
    </React.Fragment>
  )
}

export default PrivacyPolicy;