import React from 'react';

function PrivacyPolicyTerms() {
  return (
    <div classname="PrivacyPolicy">
    <div className="container">
         <div className="section-heading">
          <h2>Privacy Policy</h2>
          <div className="divider-shape"></div>
         </div>

      <p>At Mr. Salts, accessible from {''}
          <a href="http://www.mrsalts.com" style={{ color: 'blue' }}>http://www.mrsalts.com</a>, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains types of 
          information that is collected and recorded by Mr. Salts and how we use it. </p>
      <p>    
          If you have additional questions or require more information about our Privacy Policy,
          do not hesitate to contact us. </p>
      <div className="paragraph-divider"></div> 

      <h3>   General Data Protection Regulation (GDPR)   </h3>

      <p>We are a Data Controller of your information. Mr. Salts legal basis for collecting and
        using the personal information described in this Privacy Policy depends on the Personal
        Information we collect and the specific context in which we collect the information:</p>
      <div className="paragraph-divider"></div>
      <ul> 
       <li>Mr. Salts needs to perform a contract with you</li>
       <li>You have given Mr. Salts permission to do so</li>
       <li>Processing your personal information is in Mr. Salts legitimate interests</li>
       <li>Mr. Salts needs to comply with the law</li>
      </ul>
      <div className="paragraph-divider"></div>   
       <p>Mr. Salts will retain your personal information only for as long as is necessary for the
          purposes set out in this Privacy Policy. We will retain and use your information to the
          extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

       <p>If you are a resident of the European Economic Area (EEA), you have certain data
          protection rights. If you wish to be informed what Personal Information we hold about
          you and if you want it to be removed from our systems, please contact us. In certain
          circumstances, you have the following data protection rights:</p>
      <div className="paragraph-divider"></div>
      <ul>
        <li>The right to access, update or to delete the information we have on you.</li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ul>

      <div className="paragraph-divider"></div> 
        <h3>Log Files</h3>
        <p>Mr. Salts follows a standard procedure of using log files. These files log visitors when
            they visit websites. All hosting companies do this and a part of hosting services’ analytics.</p>

        <p>The information collected by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
          number of clicks. These are not linked to any information that is personally identifiable.</p>

        <p>The purpose of the information is for analyzing trends, administering the site, tracking users’
          movement on the website, and gathering demographic information.</p>
      <div className="paragraph-divider"></div>
          <h3>Cookies and Web Beacons</h3>
          <p>Like any other website, Mr. Salts uses ‘cookies’. These cookies are used to store information
            including visitors’ preferences, and the pages on the website that the visitor accessed or
            visited. The information is used to optimize the users’ experience by customizing our web page
            content based on visitors’ browser type and/or other information.</p>
      <div className="paragraph-divider"></div>
           <p>For more general information on cookies, please read “What Are Cookies”.</p>
      
      <div className="paragraph-divider"></div>
       <h3>Privacy Policies</h3>
       <p>You may consult this list to find the Privacy Policy for each of the advertising partners of
        Mr. Salts. Third-party ad servers or ad networks use technologies like cookies, JavaScript, or
        Web Beacons that are used in their respective advertisements and links that appear on Mr. Salts,
        which are sent directly to users’ browsers.</p>

        <p>They automatically receive your IP address when this occurs. These technologies are used to 
          measure the effectiveness of their advertising campaigns and/or to personalize the advertising
          content that you see on websites that you visit. Note that Mr. Salts has no access to or control
          over these cookies that are used by third-party advertisers.</p>

       <div className="paragraph-divider"></div>
          <h3>Third Party Privacy Policies</h3>
          <p>Mr. Salts' Privacy Policy does not apply to other advertisers or websites. Thus, we are advising
            you to consult the respective Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about how to opt-out of certain
            options. You can choose to disable cookies through your individual browser options.</p>

        <p>To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites. Online Privacy Policy Only This policy is not applicable to any information collected offline or via channels other than this website. Consent By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicyTerms;