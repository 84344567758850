import React from 'react';
import b1 from '../../assets/home-brands/Diamond Salt-01.webp';
import b2 from '../../assets/home-brands/Mago Salt-01.webp';
import b3 from '../../assets/home-brands/Serra Salt-01.webp';
import b4 from '../../assets/home-brands/YAMY SALT POUCHES-01.webp';
import b5 from '../../assets/home-brands/The Great Premium Pool Salt-01.webp';
/*import b6 from '../../assets/home-brands/Yamy-Salt-Red-2.webp';
import b7 from '../../assets/home-brands/SalRoyal-5.webp';
import b8 from '../../assets/home-brands/Salt Tower-01.webp';
import b9 from '../../assets/home-brands/Salt-Bay-2.webp';
import b10 from '../../assets/home-brands/Yamy-Salt-Blue-3.webp';*/

const HomeBrands = () => {
  const images = [b1, b2, b3, b4, b5];

  return (
    <div className="home-brands">
      <div className="section-heading">
        <h2>Our Brands</h2>
        <div className="divider-shape"></div>
        <p>
          One of our most loved services is the Private Label. Over the years we have made 
          a number of unique brands for a wide range of clients around the globe.
        </p>
      </div>
      <div className="home-brands-slider">
        {images.map((image, index) => (
          <div key={index} className="brand-slide">
            <img src={image} alt={`Brand ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeBrands;