import React from 'react';
// import divider from '../assets/divider-img.png';
import { AiFillLinkedin, AiOutlineWhatsApp, AiOutlineCopyrightCircle } from 'react-icons/ai';
import { BsTelephone, BsEnvelope } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-top">
            <div className="footer-top-left">
              <div className="git">
                <h4>GET IN TOUCH</h4>
              </div>
              <div className="git-2">
                <h3>
                  Get Your Right Service, <br />Connect With Us Now.
                </h3>
              </div>
            </div>
            <div className="footer-top-rights">
              <div className="footer-contact">
              <a href="https://api.whatsapp.com/send/?phone=%2B201220066112&text=Hello%20Mr.%20Salts!%20I%20have%20an%20inquiry%20about%20your%20products." target="_blank" rel="noopener noreferrer">
                <button>Contact Us</button>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="bottom-content">
              <h5>Mr. Salts has the salt you need.</h5>
              <p>
                We will be with you throughout the process. From choosing the product to delivering it to you.

              </p>
              <div className="footer-icons">
              <a href="https://www.linkedin.com/in/hisham-kassem-143388121/" target="_blank" rel="noopener noreferrer">
                <AiFillLinkedin />
              </a>
                <a href="https://api.whatsapp.com/send/?phone=%2B201220066112&text=Hello%20Mr.%20Salts!%20I%20have%20an%20inquiry%20about%20your%20products." target="_blank" rel="noopener noreferrer">
                <AiOutlineWhatsApp />
                </a>
              </div>
            </div>
            <div className="bottom-content bottom-content-center">
              <h5>Quick Links</h5>
              <ul className="services-ul">
                <Link to="/">
                  <li>Home</li>
                </Link>
                <Link to="/about">
                  <li>About Us</li>
                </Link>
                <Link to="/products">
                  <li>Products</li>
                </Link>
                <Link to="/logistics">
                  <li>Logistics</li>
                </Link>
                <Link to="/get-quote">
                  <li>Get Quote</li>
                </Link>
                <Link to="/PrivacyPolicy">
                  <li>Privacy Policy</li>
                </Link>
              </ul>
            </div>
            <div className="bottom-content">
              <h5>Contact</h5>
              <div className="icon-info">
                <BsTelephone />
                  <a href="https://api.whatsapp.com/send/?phone=%2B201220066112&text=Hello%20Mr.%20Salts!%20I%20have%20an%20inquiry%20about%20your%20products."
                  target="_blank" rel="noopener noreferrer"
                  ><p>+201023292423</p></a>
              </div>
              <div className="icon-info">
                <BsEnvelope />
                <a href="mailto:info@mrsalts.com">
                  <p>info@mrsalts.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="icon-info bottom-content bottom-content-center">
              <AiOutlineCopyrightCircle />
              <p>Copyright Mr. Salts 2023. All Rights Reserved.</p>
              </div>
      </div>
    </div>
  );
};

export default Footer;
