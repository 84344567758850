import React from 'react';
import l1 from '../../assets/logistics/1.png';
import l2 from '../../assets/logistics/2.png';
import l3 from '../../assets/logistics/3.png';
import l4 from '../../assets/logistics/4.png';

const LogisticsContent = () => {
  return (
    <div className="logistics">
      <div className="container">
        <div className="log-wrapper">
          <div className="logistics-content">
            <div className="log-textual">
              <h3>Extraction Process</h3>
              <p>
              One of the oldest seasonings in the entire world, salt is used by everyone 
              around the globe. Gladly, we produce the amazing and genuine Egyptian salt 
              from Sinai, Siwa, Lake Quaroun & Port Fouad. The taste of our product is 
              divine. You can get guaranteed high-quality salt in sizes that fit better 
              in your business. We offer Fine Table Salt 0.2–1.2mm, Medium Kitchen Salt 
              2.0-3.0mm, and Coarse Salt 2.0-6.0mm, all those types are produced with 
              high-quality raw salt crystals.
              </p>
            </div>
            <div className="log-img">
              <img src={l1} alt="logistics procecss" />
            </div>
          </div>
          <div className="logistics-content">
            <div className="log-img">
              {' '}
              <img src={l2} alt="logistics procecss" />
            </div>
            <div className="log-textual">
              <h3>Processing</h3>
              <p>
              For sure, we are able to meet your needs, our production never stops, two 
              hundred and fifty metric tons of healthy white salt is processed in our 
              factories every day. Furthermore, our clients have different packaging options 
              as well, from 25kg sack to a 200g packet, and all salt bags are branded with 
              the client’s brands.
              </p>
            </div>
          </div>
          <div className="logistics-content">
            <div className="log-textual">
              <h3>Storage</h3>
              <p>
             Keeping the same regulatory essentials in check and attention at all sanitary, 
             Halal, and ISO rules, we produce genuine Egyptian Salt. This product comes 
             from Siwa, Lake Quaroun or Port Fouad – amazing Egypt’s sightseeing locations 
             – to our preparate and certified factories. It passes all procedures and 
             becomes a great good for your business in three crystal sizes. Our entire 
             final product is constantly customized as per our client’s specifications. 
             And, all of our products are packaged directly after manufacturing just to 
             make sure they leave our factories fresh, packaged, and ready to be shipped 
             for you.
              </p>
            </div>
            <div className="log-img">
              {' '}
              <img src={l3} alt="logistics procecss" />
            </div>
          </div>
          <div className="logistics-content">
            <div className="log-img">
              <img src={l4} alt="logistics procecss" />
            </div>
            <div className="log-textual">
              <h3>Loading Ships</h3>
              <p>
              These bags are then loaded into your shipping containers by following 
              high standards. We desire to retain the quality of the product throughout 
              the shipping route, for that reason, our salt bags are laminated and 
              waterproof for maximum protection. The capacity of a 20 feet container is 
              27.5 metric tons, in which, you can transport 1100 salt bags weighing 25kg 
              each.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogisticsContent;
