import React from 'react';

const HomeAbout = () => {
  return (
    <div className="homeAbout">
      <div className="container">
        <div className="home-about-wrapper">
          <div className="section-heading">
            <h2>A sneak peek into our values</h2>
            <div className="divider-shape"></div>
            <p>
              It's not a small feat to get an enterprise together. Our passion and drive
              are fueled by our values that orient us in the best direction and keep us strong
              during easy as well as difficult times.
            </p>
          </div>
          <div className="home-about-bottom">
            <div className="home-about-item mr">
              <h3>Our Philosophy</h3>
              <p>
              Make the salt production process so good that we can make customer focused long term business relationships.
              </p>
            </div>
            <div className="home-about-item mr">
              <h3>Our Mission</h3>
              <p>
                To be the biggest Egyptian salt supplier in Africa and MENA.
              </p>
            </div>
            <div className="home-about-item mr">
              <h3>What We Do</h3>
              <p>
                We make sure that our clients have what they need to be competitive in their respective markets.
              </p>
            </div>
            <div className="home-about-item">
              <h3>Our Products</h3>
              <p>
                Coarse to fine, raw to processed. The whole range's available. All produced in our very own salt factory in Egypt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
