import React from 'react';
import { PiPackageFill } from 'react-icons/pi';
import { GiChipsBag, GiPowderBag, GiCarrier, GiSaltShaker } from 'react-icons/gi';
import { BsFillBagFill } from 'react-icons/bs';

const HomeSizes = () => {
  return (
    <div className="home-sizes">
      <div className="container">
        <div className="home-sizes-wrapper">
          <div className="home-sizes-left">
            <h2>Excellent manufacturing & custom packaging!</h2>
            <div className="divider-shape"></div>
            <p>
            From Egypt's underground salines to sea salt extraction, 
            we aim to give you the highest quality salt anywhere around 
            the globe. Gladly, we produce the amazing and genuine 
            Egyptian salt. The best part is: we work with the lowest 
            prices in the market.
            </p>
          </div>
          <div className="home-sizes-right">
            <div className="home-size-item mr mt">
              <PiPackageFill />
              <h3>Private Label</h3>
              <p>
                OEM service available for all packaging types.
              </p>
            </div>
            <div className="home-size-item mr mt">
              <GiCarrier />
              <h3>Bulk Carrier</h3>
              <p>Most economical deicing & raw salt transport.</p>
            </div>
            <div className="home-size-item mr mt">
              <BsFillBagFill />
              <h3>Jumbo Bags</h3>
              <p>
                One ton bags shipped in containers.
              </p>
            </div>
            <div className="home-size-item mr mt">
              <GiPowderBag />
              <h3>Polypropylene (P.P) Sack</h3>
              <p>
                The go-to choice of most of our clients. 
              </p>
            </div>
            <div className="home-size-item mr mt">
              <GiChipsBag />
              <h3>P.P Sachets</h3>
              <p>
                1kg or lighter polybag packaging for edible salts.
              </p>
            </div>
            <div className="home-size-item mr mt">
              <GiSaltShaker />
              <h3>Bottle</h3>
              <p>
                Mr. Salts supply refined salt inside the bottle and carton box
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSizes;
