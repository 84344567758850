import React from 'react';

const HomeProducts = () => {
  return (
    <div className="home-products">
      <div className="container">
        <div className="section-heading">
          <h2>All types of <span style={{ color: '#a367f1' }}>salt</span> from Egypt to you.</h2>
          <div className="divider-shape" style={{ position: 'relative', left: '-55px' }}></div>
          <p>
          One of the oldest seasonings in the entire world, salt is used by 
          everyone around the globe. Welcome to Mr. Salts! You will find a 
          wide variety of edible and industrial salts at our place.
          </p>
        </div>
        <div className="home-products-wrapper">
          <div className="home-product-item hpi-1 mr mt">
            <div className="home-product-info">
              <h3>Deicing Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-2 mr mt">
            <div className="home-product-info">
              <h3>Single Wash Raw Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-3 mr mt">
            <div className="home-product-info">
              <h3>Double Wash Raw Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-4 mr mt">
            <div className="home-product-info">
              <h3>Edible Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-5 mr mt">
            <div className="home-product-info">
              <h3>Tablet Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-6 mr mt">
            <div className="home-product-info">
              <h3>Dishwaser Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-7 mr mt">
            <div className="home-product-info">
              <h3>Bath Salt</h3>
            </div>
          </div>

          <div className="home-product-item hpi-8 mr mt">
            <div className="home-product-info">
              <h3>Pool Salt</h3>
            </div>
          </div>
        </div>
        <div className="paragraph-divider"></div>
        </div>
      </div>
    
  );
};

export default HomeProducts;
