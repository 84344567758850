import React, { useState } from 'react';
import { BsFillTelephoneFill, BsWhatsapp } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { HiLocationMarker } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('mongodb+srv://BoomDevPlus:<password>@boomplus.edje3bz.mongodb.net/?retryWrites=true&w=majority', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        navigate('/form-submission');

        console.log('Form data submitted successfully');
      } else {
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };
  return (
    <div className="contact-form">
      <div className="container">
        <div className="contact-form-main">
          <h2 className="contact-head">Get In Touch</h2>
          <form>
            <div className="form-group mb">
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            {/* <div className="form-group mb">
              <input type="tel" placeholder="Number" />
            </div> */}
            <div className="form-group mb">
              <textarea
                rows={7}
                placeholder="Your Message"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb">
              <Link to="/form-submission" onClick={handleSubmit}>
                <button>Submit</button>
              </Link>
            </div>
          </form>
        </div>
        <div className="contact-form-info">
          <h2 className="contact-head">Ways To Reach Us</h2>
          <div className="cont-info ">
            <BsFillTelephoneFill />
            <p>+201003802680</p>
          </div>
          <div className="cont-info">
            <BsWhatsapp />
            <p>+201220066112</p>
          </div>
          <div className="cont-info">
            <AiOutlineMail />
            <p>info@mrsalts.com</p>
          </div>
          <div className="cont-info">
            <HiLocationMarker />
            <p className="p-width">
              Egypt, Cairo, Zahraa Elmaadi, Off 50th Street, Block 88, Plot 13, 
              Building # 6, 3rd Floor # 303. Landmark: NBE 50th street.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
