import React from 'react'

const AboutIntro = () => {
  return (
    <div className='about-intro'>
        <div className='container'>
            <div className='about-description'>
                <h2>
                    Mr. Salts sets up shop
                </h2>
                <div className="divider-shape"></div>
                <p>Mr. Salts has a vision for Egypt and salts together. He wants the her to be the center of the world's salt supply.
                  He set up a factory in Sinai and got to work. Day and night he is struggling to perfect the production and export processes to bring Egypt's tasty salt to the world.
                  He is enthusiastic and determined. Join him on his challenging quest and be part of a brighter future.</p>
            </div>
        </div>
    </div>
  )
}

export default AboutIntro